/* 🌟 Wrapper */
.experience-wrapper {
  padding: 40px 20px;
  color: white;
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

/* ❌ Stäng-knapp */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid #00ffffaa;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  z-index: 2;
}

.close-btn:hover {
  background: #00ffff33;
  border-color: #00ffff;
}

/* 🔠 Rubriker */
.experience-wrapper h2 {
  font-size: 2rem;
  margin-bottom: 8px;
  color: #00ffff;
  text-shadow: 0 0 10px #00ffff66;
}

.info-text {
  font-size: 1rem;
  color: #aaa;
  margin-bottom: 24px;
}

/* 📦 Kortlayout */
.experience-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;
}

.exp-block {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  transition: transform 0.3s ease;
  text-align: center;
  border: 1px solid rgba(0, 255, 255, 0.15);
  box-shadow: 0 0 8px rgba(0, 255, 255, 0.05);
}

.exp-block img {
  width: 100%;
  max-height: 140px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.exp-block img.loaded {
  opacity: 1;
}

.exp-block h3 {
  font-size: 1.1rem;
  color: #00ffff;
}

.exp-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 12px rgba(0, 255, 255, 0.2);
}

/* 🪟 Popup */
.experience-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  animation: fadeIn 0.3s ease-in-out;
}

.popup-content {
  background: #111;
  padding: 24px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 14px;
  position: relative;
  color: white;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.1);
  animation: popupSlide 0.3s ease;
}

.popup-content img {
  width: 100px;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 16px;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.popup-content img.loaded {
  opacity: 1;
}

.popup-content h3 {
  color: #00ffff;
  font-size: 1.3rem;
  margin-bottom: 12px;
}

.popup-content p {
  font-size: 1rem;
  color: #ccc;
  line-height: 1.5;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 18px;
  background: none;
  border: none;
  color: #00ffff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.popup-close:hover {
  color: #00ffcc;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes popupSlide {
  from { transform: translateY(-10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* 🧠 Tic Tac Toe */
.board {
  display: grid;
  grid-template-columns: repeat(3, 90px);
  grid-template-rows: repeat(3, 90px);
  gap: 12px;
  justify-content: center;
  margin: 30px auto 20px auto;
}

.cell {
  background: rgba(255, 255, 255, 0.06);
  border: 2px solid rgba(0, 255, 255, 0.15);
  font-size: 2.2rem;
  color: #00ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.2s ease, transform 0.1s ease;
}

.cell:hover {
  background: rgba(0, 255, 255, 0.1);
  transform: scale(1.05);
}

.game-result {
  margin-top: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.game-result h3 {
  font-size: 1.4rem;
  color: #00ff88;
  margin-bottom: 10px;
}

.reset-button {
  margin-top: 12px;
  padding: 10px 20px;
  border: 1px solid #00ffff88;
  background: transparent;
  color: #00ffff;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(2px);
}

.reset-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 12px #00ffff66;
}

/* 🔗 Länk i popup */
.exp-link {
  display: inline-block;
  margin-top: 16px;
  color: #00ffff;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid rgba(0, 255, 255, 0.3);
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.02);
}

.exp-link:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 8px #00ffff55;
}

/* 📱 Mobilanpassning */
@media (max-width: 600px) {
  .board {
    grid-template-columns: repeat(3, 70px);
    grid-template-rows: repeat(3, 70px);
    gap: 10px;
  }

  .cell {
    font-size: 1.8rem;
  }

  .game-result h3 {
    font-size: 1.2rem;
  }

  .reset-button {
    font-size: 0.95rem;
    padding: 8px 16px;
  }

  .popup-content {
    padding: 18px;
    max-width: 90vw;
    max-height: 85vh;
  }

  .popup-content h3 {
    font-size: 1.1rem;
  }

  .popup-content p {
    font-size: 0.95rem;
  }

  .popup-content img {
    max-height: 100%;
  }
}
