/* ================================
   🔲 Global Styling
================================ */
html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #000;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', 'Segoe UI', sans-serif;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ================================
   🌿 Top Footer (was bottom)
================================ */
.footer-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #00ffff;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  z-index: 50;
  background: rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  padding: 8px 10px;
  text-shadow: 0 0 4px #ff0000;
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 10px rgba(0, 255, 255, 0.2);
  line-height: 1.4;
}

.footer-info strong {
  font-weight: 600;
}

.footer-info::after {
  content: '🌿';
  margin-left: 6px;
  animation: swing 1.5s infinite ease-in-out;
  display: inline-block;
}

@keyframes swing {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

/* ================================
   🎮 Huvudcontainer
================================ */
.character-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  perspective: 1200px;
  overflow: hidden;
  z-index: 0;
}

/* ================================
   🧍 Karaktären
================================ */
.character {
  position: absolute;
  width: 80px;
  height: 80px;
  transform-style: preserve-3d;
  transition: transform 0.3s ease-in-out, top 0.2s ease, left 0.2s ease;
  z-index: 2;
}

.character-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  filter: drop-shadow(0 8px 18px rgba(64, 255, 0, 0.3)) drop-shadow(0 0 12px rgba(4, 255, 255, 0.521));
}

/* ================================
   🧭 Header (Navigation)
================================ */
.portfolio-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
}

.nav-links {
  position: relative;
  width: 100%;
  height: 100%;
}

.nav-item {
  position: absolute;
  pointer-events: all;
  padding: 10px 20px;
  border: 2px solid rgba(255,255,255,0.15);
  border-radius: 12px;
  background: rgba(255,255,255,0.05);
  backdrop-filter: blur(8px);
  color: white;
  font-weight: 500;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.nav-item span {
  color: inherit;
}

.nav-item.active {
  border-color: #00ffff;
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 10px #00ffff66;
  color: #00ffff;
}

/* ================================
   💻 Desktop Positioner
================================ */
.nav-item.about     { top: 150px; left: 190px; }
.nav-item.education { top: 190px; right: 140px; }
.nav-item.experience { bottom: 300px; left: 130px; }
.nav-item.contact   { bottom: 320px; right: 160px; }

/* ================================
   🕹️ Pilkontroller
================================ */
.controls {
  position: fixed;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

.arrow-grid {
  display: grid;
  grid-template-areas:
    ". up ."
    "left down right";
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  justify-items: center;
  align-items: center;
}

.control-button.up    { grid-area: up; }
.control-button.left  { grid-area: left; }
.control-button.down  { grid-area: down; }
.control-button.right { grid-area: right; }

.control-button {
  width: clamp(55px, 13vw, 80px);
  height: clamp(55px, 13vw, 80px);
  font-size: clamp(22px, 5vw, 32px);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.06);
  color: #ffffff;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3), inset 0 0 10px rgba(255, 255, 255, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;
}

.control-button:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: scale(1.05);
  box-shadow: 0 6px 14px rgba(0, 255, 255, 0.2);
}

.control-button:active {
  transform: scale(0.95);
  background: rgba(238, 26, 26, 0.3);
}

/* ================================
   🟢 Meny-knapp (vid highlight)
================================ */
.menu-activate-button {
  position: fixed;
  top: 95px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: #00ffff10;
  color: #00ffff;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #00ffff88;
  border-radius: 10px;
  cursor: pointer;
  animation: pulse 1.2s infinite;
  backdrop-filter: blur(4px);
  text-shadow: 0 0 6px #00ffff;
}

@keyframes pulse {
  0%   { transform: translateX(-50%) scale(1); opacity: 1; }
  50%  { transform: translateX(-50%) scale(1.05); opacity: 0.8; }
  100% { transform: translateX(-50%) scale(1); opacity: 1; }
}

.menu-activate-button:hover {
  background: #00ffff20;
  border-color: #00ffff;
}

/* ================================
   📱 Mobilanpassning (Uppdaterad)
================================ */
@media (max-width: 600px), (hover: none) {
  /* 🌿 Top Footer */
  .footer-info {
    font-size: 9px;
    padding: 3px 0;
    line-height: 1.3;
    border-bottom-width: 1px;
    backdrop-filter: blur(2px);
  }

  .footer-info strong {
    display: inline-block;
    margin: 0 2px;
  }

  /* 🧍 Karaktär */


  /* 🧭 Navigation */
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 19px;
    padding-top: 0vh;
  }

  .nav-item {
    position: static !important;
    width: 70vw;
    max-width: 300px;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    text-align: center;
    white-space: normal;
    font-size: 14px;
  }

  /* 🕹️ Pilkontroller */
  .controls {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20dvh; /* eller 120px beroende på din design */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  

  .arrow-grid {
    gap: 9px;
    max-width: 260px;
    margin-bottom: 90px;
  
  }

  .control-button {
    width: clamp(40px, 10vw, 60px);     /* Tidigare 55–80px */
    height: clamp(40px, 10vw, 60px);
    font-size: clamp(18px, 4vw, 26px);  /* Tidigare 22–32px */
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.06);
    color: #ffffff;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3), inset 0 0 10px rgba(255, 255, 255, 0.05);
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  

  /* 🟢 Meny-knapp */
  .menu-activate-button {
    top: 98px;
    bottom: auto ;
    font-size: 19px;
    padding: 14px 20px;
    white-space: nowrap;
  }

  /* Justera rörelseavstånd för mindre skärmar */
  .character-container {
    --move-distance: 10px;
  }

  /* Disable hover-effekter på touch-enheter */
  @media (hover: none) {
    .control-button:hover {
      transform: none;
      background: rgba(255, 255, 255, 0.06);
    }
  }
}
@media(max-width: 600px) {
  
  .arrow-grid {
    gap: 9px;
    max-width: 260px;
    margin-bottom: 200px;
  
  }
}
/* ================================
   📲 Extra små skärmar (t.ex. iPhone SE)
================================ */
@media (max-width: 320px) {
  .nav-item {
    font-size: 13px;
    padding: 10px 15px !important;
  }

  .menu-activate-button {
    font-size: 13px;
    padding: 8px 16px;
    bottom: 60px;
  }

  .control-button {
    width: 12vw;
    height: 12vw;
    max-width: 50px;
    max-height: 50px;
    font-size: 15px;
    touch-action: manipulation;
  }
  
}

