.about-wrapper {
    padding: 40px 24px;
    background-color: #0d0d0d;
    border-radius: 16px;
    color: #f2f2f2;
    font-family: 'Poppins', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.1);
    animation: fadeIn 0.5s ease;
    overflow: hidden;
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(0, 255, 255, 0.4);
    color: #00ffff;
    border-radius: 8px;
    font-size: 18px;
    padding: 6px 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(4px);
  }
  
  .close-btn:hover {
    background: rgba(0, 255, 255, 0.1);
    box-shadow: 0 0 8px #00ffff88;
  }
  
  .about-content {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;
  }
  
  .profile-img {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 255, 0.3);
    box-shadow: 0 0 12px rgba(0, 255, 255, 0.2);
  }
  
  .text-info {
    flex: 1;
    min-width: 260px;
  }
  
  .text-info h2 {
    margin-bottom: 12px;
    color: #00ffff;
    font-size: 1.8rem;
    text-shadow: 0 0 8px rgba(0, 255, 255, 0.4);
  }
  
  .text-info p {
    font-size: 1rem;
    line-height: 1.6;
    color: #ccc;
    margin-bottom: 16px;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  /* 📱 Mobilanpassning */
  @media (max-width: 600px) {
    .about-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .profile-img {
      width: 120px;
      height: 120px;
    }
  
    .text-info h2 {
      font-size: 1.5rem;
    }
  
    .text-info p {
      font-size: 0.95rem;
    }
  }
  .profile-img {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
  
  .profile-img.loaded {
    opacity: 1;
  }
  