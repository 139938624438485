/* ================================
   💻 Jungle Style Desktop Positioner
================================ */
.nav-item.about {
  top: 22%;
  left: 9%;
  transform: rotate(-2deg);
}

.nav-item.education {
  top: 41%;
  right: 11%;
  transform: rotate(1.5deg);
}

.nav-item.experience {
  bottom: 30%;
  left: 18%;
  transform: rotate(-3deg);
}

.nav-item.contact {
  bottom: 12%;
  right: 14%;
  transform: rotate(2deg);
}

/* ================================
   🎨 Jungle Aesthetic Look
================================ */
.nav-item {
  position: absolute;
  pointer-events: all;
  padding: 12px 24px;
  border: 2px solid rgba(255,255,255,0.12);
  border-radius: 16px;
  background: rgba(0, 60, 30, 0.18); /* Jungle glass effect */
  backdrop-filter: blur(6px);
  color: white;
  font-weight: 500;
  transition: all 0.25s ease;
  white-space: nowrap;
  box-shadow: 0 0 12px rgba(0, 255, 170, 0.15),
              0 0 40px rgba(0, 255, 170, 0.05);
}

.nav-item span {
  color: inherit;
  font-size: 16px;
}

.nav-item.active {
  border-color: #00ffcc;
  background: rgba(0, 255, 170, 0.1);
  box-shadow: 0 0 12px #00ffcc66;
  color: #00ffcc;
}

/* ================================
   🪟 Panelcontainer (overlay)
================================ */
.panel-container {
  display: block;
  position: fixed;
  inset: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  overflow-y: auto;
  padding: 2rem;
  animation: fadeIn 0.4s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to   { opacity: 1; transform: translateY(0); }
}
