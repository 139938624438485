/* src/Style/ContactMe.css */
.lux-contact-wrapper {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    padding: 40px 20px;
    background: #000; /* Black background for a "luxury" feel */
    border: 2px solid #ffd700; /* Golden border */
    border-radius: 12px;
    text-align: center;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    animation: fadeIn 0.5s ease-in-out;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
  }
  
  /* Close button (top-right corner) */
  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background: transparent;
    border: none;
    font-size: 1.4rem;
    color: #ffd700;
    cursor: pointer;
    transition: transform 0.2s;
  }
  .close-btn:hover {
    transform: scale(1.2);
  }
  
  .lux-contact-wrapper h2 {
    font-size: 2rem;
    margin-bottom: 16px;
    color: #ffd700; /* Gold */
  }
  
  .lux-intro {
    font-size: 1rem;
    color: #ddd;
    margin-bottom: 24px;
    line-height: 1.6;
  }
  
  /* Icon container row */
  .lux-contact-icons {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .icon {
    font-size: 3rem;   /* Large icons */
    color: #ffd700;    /* Gold color */
    text-decoration: none;
    transition: transform 0.2s, text-shadow 0.2s;
    cursor: pointer;
  }
  .icon:hover {
    transform: scale(1.2);
    text-shadow: 0 0 10px #ffd700;
  }
  
  /* Simple fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  